<template>
    <div class="px-4 text-center text-light nome-parlamentar">
        <span>{{ nomeVereador }}</span>
    </div>
</template>

<script>
export default {
    props: {
        vereador: {
            type: Object,
            required: true
        },
        exibirApenasApelidoVereador: {
            type: Boolean,
            required: true
        }
    },

    computed: {
        nomeVereador() {
            const apelido = this.vereador.apelido || '';
            const nomeComum = this.vereador.pessoa.nome;
            if (!apelido) return nomeComum;
            if (this.exibirApenasApelidoVereador) return apelido;
            return `${nomeComum} "${apelido}"`;
        }
    }
};
</script>

<style scoped lang="scss">
.nome-parlamentar {
    font-size: 1em;
    margin-bottom: 0.4em;
    @media (min-width: 640px) { font-size: 1.2em; }
    @media (min-width: 768px) { font-size: 1.5em; }
    @media (min-width: 1024px) { font-size: 1.8em; }
    @media (min-width: 1280px) { font-size: 2.1em; }
    @media (min-width: 1536px) { font-size: 2.5em; }
    @media (min-width: 2000px) { font-size: 2.8em; }
    @media (min-width: 2400px) { font-size: 3.1em; }
    @media (min-width: 2800px) { font-size: 3.4em; }
    @media (min-width: 3200px) { font-size: 3.7em; }
    @media (min-width: 3600px) { font-size: 4.1em; }
    @media (min-width: 4000px) { font-size: 4.5em; }
}
</style>
